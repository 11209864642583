<template>
    <ul class="slider" :class="[ratio ? 'ratio-' + ratio : '']">
        <transition-group :name="animation">
            <li
                v-for="(slide, index) in slides"
                v-show="index === currentSlide"
                :key="'slide_' + index"
                class="bg-cover"
                :style="{ backgroundImage: 'url(' + slide.image + ')' }"
            >
                <slot name="slide" :slide="slide" />
            </li>
        </transition-group>
    </ul>
</template>

<script>
export default {
    name: 'Slider',
    props: {
        slides: {
            type: Array,
            default: () => [],
        },
        animation: {
            type: String,
            default: 'list-complete',
        },
        ratio: String,
        delay: {
            type: Number,
            default: 6000,
        },
        value: Number, // Currentslide
    },
    data() {
        return {
            timer: null,
            currentSlide: 0,
        };
    },
    mounted() {
        this.startTimer();
        this.currentSlide = typeof this.value === 'number' ? this.value : 0;
    },
    methods: {
        move(direction = 'next') {
            const nextIndex = direction === 'next' ? this.currentSlide + 1 : this.currentSlide - 1;
            const nextSlideIndex = nextIndex % this.slides.length;

            if (typeof this.value === 'number') {
                this.$emit('input', nextSlideIndex);
            } else {
                this.slideTo(nextSlideIndex);
            }
        },

        startTimer() {
            this.timer = setTimeout(this.move, this.delay);
        },

        slideTo(val) {
            clearTimeout(this.timer);
            this.currentSlide = val;
            this.startTimer();
        },
    },
    watch: {
        value: 'slideTo',
    },
};
</script>

<style>
.slider {
    margin: 0;
    position: relative;
}
    .slider li {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>
